import {useEffect,  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
const HomeOne = lazy(() => import("./pages/HomeOne"));
const HomeTwo = lazy(() => import("./pages/HomeTwo"));
const HomeThree = lazy(() => import("./pages/HomeThree"));
const About = lazy(() => import("./pages/About"));
const Service = lazy(() => import("./pages/Service"));
const Work = lazy(() => import("./pages/Work"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogClassic = lazy(() => import("./pages/BlogClassic"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Contact = lazy(() => import("./pages/Contact"));
const CareerDetails = lazy(() => import("./pages/CareerDetails"));
const RecruiterConsultant = lazy(() => import("./pages/career/RecruiterConsultant"));
const SocialMediaExecutive = lazy(() => import("./pages/career/SocialMediaExecutive"));
const MarketingContentExecutive = lazy(() => import("./pages/career/MarketingContentExecutive"));
const BusinessDevelopmentManager = lazy(() => import("./pages/career/BusinessDevelopmentManager"));
const MotionGraphicDesigner = lazy(() => import("./pages/career/MotionGraphicDesigner"));
const PHPLaravelDeveloper = lazy(() => import("./pages/career/PHPLaravelDeveloper"));
const InsideSalesRepresentative = lazy(() => import("./pages/career/InsideSalesRepresentative"));


function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
          <Suspense fallback={<div />}>
                <Routes>
                  <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} element={<HomeOne/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/career"}`} element={<HomeTwo/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} element={<HomeThree/>}/>
                  <Route path={`${process.env.PUBLIC_URL + "/about"}`} element={<About/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/service"}`} element={<Service/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/areaofexpertise"}`} element={<Work/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} element={<WorkDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog"}`} element={<BlogGrid/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} element={<BlogClassic/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} element={<BlogTag/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} element={<BlogCategories/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/blog/:string"}`}element={<BlogDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/contact"}`} element={<Contact/>} />
                  <Route path={`${process.env.PUBLIC_URL + "/careerdetails"}`} element={<CareerDetails/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/recruiterconsultant"}`} element={<RecruiterConsultant/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/socialmediaExecutive"}`} element={<SocialMediaExecutive/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/marketingcontentexecutive"}`} element={<MarketingContentExecutive/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/businessdevelopmentmanager"}`} element={<BusinessDevelopmentManager/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/motiongraphicdesigner"}`} element={<MotionGraphicDesigner/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/phplaraveldeveloper"}`} element={<PHPLaravelDeveloper/>} />
                  <Route path={`${process.env.PUBLIC_URL + "career/insidesalesrepresentative"}`} element={<InsideSalesRepresentative/>} />
                  
                  
                </Routes>
            </Suspense>
        </NavScrollTop>
      </Router>
  );
}

export default App;
